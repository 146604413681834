import React, { Component } from 'react';
import Banner from './banner';

class Home extends Component {
  render() {
    return(
      <Banner />
    )
  }
}

export default Home;
